import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Rightcontainer from "../Components/Rightcontainer";
import Accordion from "@mui/material/Accordion";
// import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//import Header from "../Components/Header";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

import "../css/terms.css";
//import { Interweave } from "interweave";
const All_policy = () => {
  const [data, setData] = useState();
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;

  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    var baseUrl = beckendLiveApiUrl;
  }

  return (
    <div>
      <div
        className="leftContainer"
        style={{ minHeight: "100vh", height: "100%" }}
      >
        <div className="mt-5 policy_details_link py-4 px-3">
          <Link className=" text-dark p-2 text-decoration-none" to="/refund-policy">
            <li className="d-flex justify-content-between w-100 align-items-center col text-dark giveHover">
              Refund/Cancellatioin Policy <MdOutlineKeyboardArrowRight />
            </li>
          </Link>
          <Link className=" text-dark p-2 text-decoration-none" to="/term-condition">
            <li className="d-flex justify-content-between w-100 align-items-center col text-dark giveHover">
              Terms & Conditions <MdOutlineKeyboardArrowRight />
            </li>
          </Link>
          <Link className=" text-dark p-2 text-decoration-none" to="/PrivacyPolicy">
            <li className="d-flex justify-content-between w-100 align-items-center col text-dark giveHover">
              Privacy policy <MdOutlineKeyboardArrowRight />
            </li>
          </Link>

          <Link className=" text-dark p-2 text-decoration-none" to="/contact-us">
            {" "}
            <li className="d-flex justify-content-between w-100 align-items-center col text-dark giveHover">
              Contact Us <MdOutlineKeyboardArrowRight />
            </li>{" "}
          </Link>
          <Link className=" text-dark p-2 text-decoration-none" to="/responsible-gaming">
            {" "}
            <li className="d-flex justify-content-between w-100 align-items-center col text-dark giveHover">
              Responsible Gaming <MdOutlineKeyboardArrowRight />
            </li>
          </Link>
          <Link className=" text-dark p-2 text-decoration-none" to="About">
            {" "}
            <li className="d-flex justify-content-between w-100 align-items-center col text-dark giveHover">
              About Us <MdOutlineKeyboardArrowRight />
            </li>{" "}
          </Link>
        </div>
      </div>
      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </div>
  );
};
export default All_policy;
