import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "../css/landing.css";
import Downloadbutton from "../Components/Downloadbutton";
import Swal from "sweetalert2";
import whatsapp from '../../assets/images/whatsapp.png'
export default function Landing() {
 
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  var baseUrl;
  if (nodeMode === "development") {
    baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  const [open, setOpen] = useState(false);
  const [userAllData, setUserAllData] = useState();
  const [commission, setcommission] = useState();
  const [refer, setrefer] = useState();
  const [WebSitesettings, setWebsiteSettings] = useState("");
  const [blockMessage, setBlockMessage] = useState("");
  const [userBlock, setUserBlock] = useState(0);
  const [msg, setmsg] = useState("");
    
  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    setcommission(data.commission);
    setrefer(data.refer);
    setmsg(data.msg);

    return setWebsiteSettings(data);
  };

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUserAllData(res.data);
        const passworduser= res.data.Password;
      })

      .catch((e) => {
        if (e.response?.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
        }
      });
  };

  useEffect(() => {
    if (process.env.REACT_APP_DEBUG === 'true') {
      
    }
    
    let access_token = localStorage.getItem("token");
    access_token = localStorage.getItem("token");
    if (!access_token) {
      
    }
    role();
    fetchData();
  }, [userBlock]);

  return (
    <>

<div>
    </div>

    {userBlock > 0 ? (
        <div>{blockMessage}</div>
      ) : (
        <div>Login Panel</div>
      )}

      <div className="leftContainer">
        
        <div className="main-area" style={{ paddingTop: "35px" }}>
        <div>
  
</div> 

          <div className="header_top_message">
            <span>Commission:5% ◉ Referral: 2% For All Games</span> 
          </div>
          <div className="header_top_message">
          <span>{msg}</span>
          </div>

          <section className="games-section p-3">
          
            <div className="d-flex align-items-center games-section-title"></div>
            <div className="games-section-headline mt-2 mb-1">
              <div className="games-window">
                <Link
                  className="gameCard-container"
                  to={`/Homepage/LudoClassicsLite`}
                >
                 
                  <picture className="gameCard-image rounded-lg">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL +
                        "/Images/LandingPage_img/Ludo_classic.png"
                      }
                      
                      alt=""
                    />
                  </picture>
                
                </Link>
                <Link className="gameCard-container">
                 
                  <picture className="gameCard-image rounded-lg">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL +
                        "/Images/LandingPage_img/Ludo_popular.png"
                      }
                      alt=""
                    />
                  </picture>
                 
                </Link>

                <Link className="gameCard-container">
                  
                  <picture className="gameCard-image rounded-lg">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL +
                        "/Images/LandingPage_img/ludo-SNK.png"
                      }
                      alt=""
                    />
                  </picture>
                </Link>

                <Link className="gameCard-container">
                 
                  <picture className="gameCard-image rounded-lg">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL +
                        "/Images/LandingPage_img/rummy.png"
                      }
                      alt=""
                    />
                  </picture>
                </Link>
              </div>
            </div>
            <hr></hr>
          </section>

         

       

          <section className="footer">
            <div className="footer-divider" />
          </section>
          {/* <div className="downloadButton">
            <Downloadbutton />
          </div> */}

          <div class="whatapp_link">
            <Link to="https://api.whatsapp.com/send?phone=919257921136&amp;text="><img src={whatsapp} alt="whatsapp" width={50}/></Link>
          </div>
        </div>
      </div>
      {/* // <div className='rightContainer'>
            //     <Rightcontainer/>
            // </div> */}
    </>
  );
}
