import React, { useEffect, useState } from "react";
import Rightcontainer from "../Components/Rightcontainer";
import axios from "axios";

const Support = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;

  var baseUrl;
  if (nodeMode === "development") {
    baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const access_token = localStorage.getItem("token");
  const [user, setUser] = useState();
  const getUser = () => {
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUser(res.data);
      })
      .catch((e) => {
        if (e.response?.status === 401) {
          localStorage.removeItem("token");
          // history.pushState("/login")
        }
      });
  };

  const [WebSitesettings, setWebsiteSettings] = useState("");
  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    return setWebsiteSettings(data);
  };

  useEffect(() => {
    fetchData();
    getUser();
  }, []);

  return (
    <div>
      <div
        className="leftContainer"
        style={{ minHeight: "100vh", height: "100%" }}
      >
        <div className="cxy flex-column " style={{ paddingTop: "16%" }}>
          <img
            src={process.env.PUBLIC_URL + "/Images/contact_us.png"}
            width="280px"
            alt=""
          />
          <div
            className="games-section-title mt-4"
            style={{ fontSize: "1.4em", fontWeight: "700", color: "2c2c2c" }}
          >
            Contact us at below platforms.
          </div>

          <div className="row">
            {/* <div className="col-6  d-flex justify-content-around w-80">
              <a className="cxy flex-column" href={"https://t.me/rkbattle"}>
                <img
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/tel.png"}
                  alt=""
                />

                <span className="footer-text-bold">telegram</span>
              </a>
            </div> */}

            <div className="col-6  d-flex justify-content-around w-80 ">
              <a
                className="cxy flex-column"
                href={
                  "https://wa.me/+919257921136?text=hello%20admin%20i%20need%20help"
                }
              >
                <img
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/whatsapp.png"}
                  alt=""
                />
                <span className="footer-text-bold">whatsapp</span>
              </a>
            </div>
          </div>

          <div
            className="games-section-title mt-4"
            style={{ fontSize: "1.4em", fontWeight: "800", color: "2c2c2c" }}
          >
            Support Time 24 X 7.
          </div>
          {/* <div style={{ textAlign: "center", padding: "10px 20px" }}>
            <p>
              {" "}
              Dhani natwaryon ki chhapda khurd , Ward no. 5, Shahpura
              <br /> Jaipur, Rajsthan 303103
            </p>

            <p>CONTACT NO : +91 8769022045</p>

            <h3>RK Battle Private Limited</h3>
          </div> */}
        </div>
      </div>

      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </div>
  );
};
export default Support;
